export default {
  pollInterval: 5000,
  pollTimeout: 1900,
  kelvin: 3000,
  colors: [
    'red',
    'rgb(255,32,0)',
    'orangeRed',
    'yellow',
    'lime',
    'springGreen',
    'cyan',
    'Aquamarine',
    'DeepSkyBlue',
    'blue',
    'rgb(127,0,255)',
    'Magenta',
    'rgb(255,0,127)',
    'rgb(255,0,4)',
  ],
}
